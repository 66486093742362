import React, { useState } from "react";
import CouponDisplay from "./CouponDisplay";

function PhoneCoupon({
  img,
  text,
  company,
  corner_text,
  sponsored,
  description,
  overlayText,
  coupon,
  redirectLink,
}) {
  const [showCoupon, setShowCoupon] = useState(false);

  const handleCouponClick = () => {
    setShowCoupon(true);
  };

  return (
    <React.Fragment>
      <div className="coupon" onClick={handleCouponClick}>
        <a href={redirectLink} target="_blank">
          <div className="d-flex align-items-center">
            <img src={img} className="coupon_img h-100" alt={company} />
            <div className="d-flex flex-column p-3">
              <h3 className="coupon_company my-2">{text}</h3>
              <div className="coupon-desc fw-500">{description}</div>
              <CouponDisplay
                phone={true}
                imgUrl={img}
                name={company}
                description={description}
                code={coupon}
                showCoupon={showCoupon}
                setShowCoupon={setShowCoupon}
                redirect={redirectLink}
              />
            </div>
          </div>
        </a>
      </div>
    </React.Fragment>
  );
}

export default PhoneCoupon;
