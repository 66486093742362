import React from "react";

function CouponDisplay({
  imgUrl,
  name,
  description,
  code,
  showCoupon,
  setShowCoupon,
  redirect,
  phone
}) {
  const handleClose = (event) => {
    event.stopPropagation(); // Prevent click event from bubbling up
    event.preventDefault(); // Prevent default action of the close button
    setShowCoupon(false);
  };

  const handleCopy = (event) => {
    event.stopPropagation(); // Prevent click event from bubbling up
    navigator.clipboard.writeText(code);
    // Check if the Clipboard API is supported
    if (navigator.clipboard) {
      // Call the writeText method to copy the text to the clipboard
      navigator.clipboard
        .writeText(code)
        .then(() => {
          // console.log("Text copied to clipboard");
        })
        .catch((error) => {
          // console.error("Unable to copy text to clipboard:", error);
        });
    } else {
      // Fallback for browsers that do not support the Clipboard API
      // You can use the method I provided in the previous response for compatibility
      const textarea = document.createElement("textarea");
      textarea.value = code;
      textarea.style.position = "fixed";
      textarea.style.opacity = "0";
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      console.log("Text copied to clipboard (fallback)");
    }
  };

  return (
    <>
      <div
        className="coupon-code"
        onClick={() => {
          setShowCoupon(true);
        }}
        style={{color: "rgb(116 31 162/1)"}}
      >
        <a href={redirect} target="_blank">Coupon Code</a>
      </div>
      {showCoupon && (
        <div
          className="w-100vw h-100vh coupon-container"
          style={{
            top: "0px",
            backgroundColor: "rgb(193 193 193 / 50%)",
            backdropFilter: "blur(3px)",
          }}
          onClick={handleClose} // Close coupon when clicking outside the content
        >
          <div
            className="coupon-content-container"
            onClick={(event) => event.stopPropagation()}
          >
            <div className="coupon-display-box w-100p p-relative d-flex flex-column align-items-center">
              <button className="close-btn" onClick={handleClose}>
                X
              </button>
              <div className="company-logo-container my-3 resp-my-0">
                <img src={imgUrl} className="company-coupon-logo" />
              </div>
              <div className="coupon-company my-2" style={{color: "black"}}>{name}</div>
              <div className="coupon-offering mb-4" style={{color: "black"}}>{description}</div>
              <div className="coupon-box mb-2" style={{color: "black"}}>
                <div className="coupon-code-box">{code}</div>
                <button className="copy-btn" onClick={handleCopy}>
                  COPY
                </button>
              </div>
              <div className="small-desc my-2" style={{color: "black"}}>
                Copy and paste this code at {name}
              </div>
            </div>  
            <div className="coupon-display-bottom px-4 pb-4">
              <div className="my-5 resp-mt-1r" style={{color: "black"}}>Details: {description}</div>
              <div className="more text-left underline" style={{color: "black"}}>MORE DETAILS</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CouponDisplay;
