import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { base, fetchBrandData } from "../../functions/functions.ts";
import Coupon from "./Coupon";
import PhoneCoupon from "./PhoneCoupon";

function BrandPage() {
  const { brandTitle } = useParams();
  const [brandData, setBrandData] = useState(null);
  const [products, setProducts] = useState([]); // Array to store products
  const [loading, setLoading] = useState(true); // State to track loading status
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    fetchBrandData(brandTitle, setBrandData, setProducts, setLoading);

    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1000);

    // Add event listener for screen size change
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 430);
    };

    window.addEventListener("resize", handleResize);

    // Initial check for screen size on component mount
    setIsSmallScreen(window.innerWidth < 430);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [brandTitle]);

  return (
    <div className="brand-container">
      <div className="header-img-container mb-5">
        <img src={base + brandData?.headerImg} alt="Brand Header" />
      </div>
      <div className="d-flex w-80p mx-auto justify-content-between brand-data-container">
        <div className="w-50p">
          <h3>{brandData?.title}</h3>
          <div
            className="my-3 brand-desc"
            style={{ fontSize: "large", fontFamily: "Montserrat" }}
          >
            {brandData?.sideText}
          </div>
        </div>
        <div className="w-40p d-flex justify-content-center">
          <img
            src={base + brandData?.sideBySideImg}
            className="w-50p rounded-img"
            alt="Side by Side"
          />
        </div>
      </div>
      <hr style={{ width: "80%", marginLeft: "auto", marginRight: "auto" }} />
      <div className="w-80p mx-auto my-5">
        <h3 className="my-5">Products from {brandData?.title}</h3>
        {loading ? (
          <p>Loading...</p>
        ) : products.length === 0 ? (
          <h3 className="text-center my-5">No data to display</h3>
        ) : (
          <div className="d-flex flex-wrap products-container my-3">
            {products.map((product, index) => (
              // Conditionally render Coupon or PhoneCoupon based on screen width
              isSmallScreen ? (
                <PhoneCoupon
                  key={index}
                  img={base + product.image}
                  text={product.title}
                  company={product.brand}
                  overlayText={product.overlayText}
                  redirectLink={product.backLink}
                  description={product.description}
                  coupon={product.couponCode}
                />
              ) : (
                <Coupon
                  key={index}
                  img={base + product.image}
                  text={product.title}
                  company={product.brand}
                  overlayText={product.overlayText}
                  redirectLink={product.backLink}
                  description={product.description}
                  coupon={product.couponCode}
                />
              )
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default BrandPage;
