import React from 'react'
import stars from "../resources/images/stars_for_footer.png"
import cashBack from "../resources/images/cashback.png"

function Footer() {
  return (
    <div className='pt-4 d-flex flex-column footer'>
        <div className='d-flex w-80p mx-auto my-5 justify-content-between resp-flex-wrap-780 footer-box'>
            <div className='d-flex flex-column footer-data'>
                <div className='hover_underline'><img src={cashBack} style={{width: "30px"}}/>CASH BACK</div>
                <div className='hover_underline'>BROWSE STORES</div>
                <div className='hover_underline'>BROWSE CATEGORIES</div>
                <div className='hover_underline'>THE REAL DEAL BLOG</div>
                <div className='hover_underline'>CAREERS</div>
            </div>
            <hr style={{display: "none"}} className='phone-view'/>
            <div className='d-flex flex-column footer-data'>
                <div className='hover_underline'>MY UKM</div>
                <div className='hover_underline'>My Account + Rewards</div>
                <div className='hover_underline'>RMN Community</div>
                <div className='hover_underline'>Submit A Coupon</div>
                <div className='hover_underline'>Share Feedback</div>
                <div className='hover_underline'>Get Help</div>
            </div>
            <hr style={{display: "none"}} className='phone-view'/>
            <div className='d-flex flex-column footer-data'>
                <div>GET DEAL FINDER</div>
                <div className='w-90p resp-w-100p'>Automatically apply codes + cash back when you shop online with <span className='underline'>Deal Finder</span></div>
            </div>
            {/* <div className='d-flex flex-column footer-data p-relative'>
                <img src={stars} className='p-absolute z-1' style={{width: '75%', transform: "translateY(-10%)"}}/>
                <div className='z-10'>DOWNLOAD THE APP</div>
                <div className='z-10'>Get app-only offers and the best of RMN</div>
                <div className='d-flex z-10'>
                    <img src='https://www.retailmenot.com/svg/deal-finder-qr.svg' className='qr-code'/>
                    <div className='d-flex flex-column'>
                        <img src='https://www.retailmenot.com/svg/apple-store.svg'/>
                        <img src='https://www.retailmenot.com/svg/google-playstore.svg'/>
                    </div>
                </div>
            </div> */}
        </div>
    </div>
  )
}

export default Footer