import React from 'react'
import AboutUsSection from '../components/AboutUsSection'

function AboutUs() {
  return (
    <div>
        <AboutUsSection/>
    </div>
  )
}

export default AboutUs