import React, { useState } from "react";
import CouponDisplay from "./CouponDisplay";

function Coupon({
  img,
  text,
  company,
  corner_text,
  sponsored,
  description,
  overlayText,
  coupon,
  redirectLink,
}) {
  const [showCoupon, setShowCoupon] = useState(false);

  const handleCouponClick = () => {
    setShowCoupon(true);
  };

  return (
    <React.Fragment>
      <div className="coupon" onClick={handleCouponClick}>
        <a href={redirectLink} target="_blank">
          {overlayText ? (
            <div className="p-absolute overlayText">{overlayText}</div>
          ) : null}
          {sponsored ? (
            <div className="sponsored text-gray">SPONSORED</div>
          ) : null}
          <div className="d-flex flex-column">
            <img src={img} className="coupon_img" alt={company} />
            <div className="d-flex flex-column p-2">
              <h3 className="coupon_company my-2">{text}</h3>
              <div className="coupon-desc fw-500">{description}</div>
            </div>
          </div>
        </a>
        <CouponDisplay
          imgUrl={img}
          name={company}
          description={description}
          code={coupon}
          showCoupon={showCoupon}
          setShowCoupon={setShowCoupon}
          redirect={redirectLink}
        />
      </div>
    </React.Fragment>
  );
}

export default Coupon;
