import React from "react";
import banner from "../resources/images/welcome_banner.png";
import peace from "../resources/images/peace.png";

function AboutUsSection() {
  return (
    <div>
      <section class="section_all bg-light" id="about">
        <img src={banner} className="welcome_banner" />
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="section_title_all text-center">
                <h3 class="font-weight-bold">
                  Welcome To <span class="text-custom">UKlickMe</span>
                </h3>
                <div class="">
                  <i class=""></i>
                </div>
              </div>
            </div>
          </div>

          <div class="row vertical_content_manage mt-5">
            <div class="col-lg-6">
              <div class="about_header_main mt-3">
                <div class="about_icon_box">
                  <p class="text_custom font-weight-bold">
                    UKLICKME helps you save money while shopping online and
                    in-store at your favorite retailers.{" "}
                  </p>
                </div>
                {/* <h4 class="about_   heading text-capitalize font-weight-bold mt-4">Lorem Ipsum is simply dummy text of the printing industry.</h4> */}
                <p class="text-muted mt-3">
                  Whether you're looking for a promo code, a coupon, a free
                  shipping offer or the latest sales, we're constantly verifying
                  and updating our best offers and deals. Plus, we provide you
                  with cash back offers to get a percentage of what you spend
                  back in your pocket. For an even easier way to save, our
                  browser extension, Deal Finder, finds and automatically
                  applies promo codes to your online shopping carts. Don't
                  forget to check out our blog for our editors' pro shopping
                  tips, the hottest sales, and the best products to buy.
                </p>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="img_about mt-3">
                <img
                  src="https://i.ibb.co/qpz1hvM/About-us.jpg"
                  alt=""
                  class="img-fluid mx-auto d-block"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AboutUsSection;
