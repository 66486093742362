import React from "react";
import img_1 from "../resources/images/save_with_retail_me_not_1.png";
import img_2 from "../resources/images/save_with_retail_me_not_2.png";
import img_3 from "../resources/images/save_with_retail_me_not_3.png";

function SavingWithUKlickMe() {
  return (
    <div className="d-flex flex-column pt-5 resp-p-0">
      {/* <div className="savecation d-flex flex-column align-items-center justify-content-center savecation-container my-5 p-3">
        <h5 className="fw-bold">SPRING SAVECATION BEGINS IN</h5>
        <div className="count_down">4d 1h 50m 1s</div>
        <span className="lets_go shop-now font-big mt-3">Let's Go</span>
      </div> */}
      <h5 className="fw-bold saving_with_UKlickMe">
        Three Ways To Save With UKlickMe
      </h5>
      <div className="d-flex justify-content-between resp-flex-wrap small-flex-column small-align-center">
        <div className="d-flex my-3 w-30p resp-w-40p small-w-80 mx-3">
          <div className="saving_card d-flex">
            <div className="saving_img">
              <img src={img_1} />
            </div>
            <div className="d-flex flex-column">
              {/* <h6 className="fw-bold my-1">CHA-CHING</h6> */}
              <div>Cash back users earn up to 10% cash back per order</div>
              <div className="shop-now fw-500 mt-3">BROWSE CASH BACK</div>
            </div>
          </div>
        </div>
        <div className="d-flex my-3 w-30p resp-w-40p small-w-80 mx-3">
          <div className="saving_card d-flex">
            <div className="saving_img">
              <img src={img_2} />
            </div>
            <div className="d-flex flex-column">
              {/* <h6 className="fw-bold my-1">SAVE ON THE GO</h6> */}
              <div>Get app-only offers and the best of UKlickMe</div>
              <div className="shop-now fw-500 mt-3">UKlickMe ON IOS</div>
              <div className="shop-now fw-500 mt-3">UKlickMe ON ANDROID</div>
            </div>
          </div>
        </div>
        <div className="d-flex my-3 w-30p resp-w-40p small-w-80 mx-3">
          <div className="saving_card d-flex">
            <div className="saving_img">
              <img src={img_3} />
            </div>
            <div className="d-flex flex-column">
              {/* <h6 className="fw-bold my-1">SAVE LIKE MAGIC</h6> */}
              <div>
                Automatically apply codes + cash back when you shop online
              </div>
              <div className="shop-now fw-500 mt-3">DEAL FINDER EXTENSION</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SavingWithUKlickMe;
