import React, { useState, useEffect } from "react";
import Logo from "../resources/images/uklickme.jpeg";
import { Link } from "react-router-dom";

const myFunction = () => {
  var x = document.getElementById("myLinks");
  if (x.style.display === "flex") {
    x.style.display = "none";
  } else {
    x.style.display = "flex";
  }
};

function Navbar() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      {windowWidth >= 768 ? ( // Render desktop navbar
        <div className="d-flex align-items-center navbar justify-content-between fw-bold">
          <Link to={"/"}>
            <div className="logo">
              <img src={Logo} alt="Logo" />
            </div>
          </Link>
          <div className="links">
            <ul>
              <Link to={"/"}>
                <li>Home</li>
              </Link>
              {/* <li>Blog</li> */}
              <Link to={"/about"}>
                <li>About Us</li>
              </Link>
              <Link to={"/contact"}>
                <li>Contact</li>
              </Link>
            </ul>
          </div>
        </div>
      ) : (
        // Render mobile navbar
        <div className="topnav d-flex flex-column ">
          <div className="d-flex bg-purple justify-content-between align-items-center">
            <div className="d-flex align-items-center justify-content-center">
              <a href="#home" className="active">
                <div className="logo">
                  <img src={Logo} alt="Logo" />
                </div>
              </a>
            </div>
            <div className="web-name">UKlickMe</div>
            <a href="javascript:void(0);" className="icon" onClick={myFunction}>
              <img
                src="https://cdn-icons-png.flaticon.com/512/3388/3388823.png"
                alt="Menu Icon"
                style={{ height: "45px" }}
                className="menu-icon"
              />
            </a>
          </div>
          <div className=" flex-column" id="myLinks">
            <Link to={"/"} onClick={myFunction}>
              <li className="phone-nav-link">Home</li>
            </Link>
            {/* <li>Blog</li> */}
            <Link to={"/about"} onClick={myFunction}>
              <li className="phone-nav-link">About Us</li>
            </Link>
            <Link to={"/contact"} onClick={myFunction}>
              <li className="phone-nav-link">Contact</li>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default Navbar;
