import React from "react";
import OrderedListing from "./res/OrderedListing";

function FAQ() {
  return (
    <div className="my-5 pt-3">
      <h3 className="fw-bold my-4">Frequently Asked Questions</h3>
      <OrderedListing
        title={"How can UKlickMe save me money when shopping online?"}
        faq={
          "UKlickMe offers several ways for shoppers to save while shopping. We feature up-to-date coupon codes, free shipping offers, sales and promo codes for thousands of stores and restaurants. Plus, our cash back offers pay you to shop! Activate a cash back offer, shop, check out, and we'll pay you back a percentage of what you spent."
        }
      />
      <OrderedListing
        title={
          "How many online stores does UKlickMe have coupons & promo codes for?"
        }
        faq={
          "UKlickMe has coupons and promo codes for more than 30,000 stores, brands and restaurants. Our team is constantly verifying the offers on our site to ensure you save money on every purchase."
        }
      />
      <OrderedListing
        title={"Does UKlickMe provide cash back for online purchases?"}
        faq={
          "Yes. UKlickMe has cash back offers for more than 1,200 stores. You can access these cash back offers on our site, via our app or through our free browser extension. Simply sign up for a UKlickMe account, activate the cash back offers you want to use, shop and check out as normal – we'll give you a percentage of what you spent back in your UKlickMe wallet. From there, you can redeem your cash back through Venmo or PayPal and use it on … whatever you want. And yes - our cash back offers stack with promo codes, too."
        }
      />
      <OrderedListing
        title={"Does UKlickMe have a browser extension?"}
        faq={
          "Yes. Install our free browser extension, Deal Finder, on your preferred browser (including top browsers like Chrome, Firefox, Safari and Microsoft Edge), to make sure you never miss out on the best promo code for your purchase. Deal Finder sources and automatically applies coupons and promo codes in real time while you shop online, which streamlines your online shopping and lets you skip the step of hunting for coupons before checking out. Deal Finder also sources cash back offers for you, so you can stack your savings."
        }
      />
      <OrderedListing
        title={"Does UKlickMe have an app I can use on my smartphone?"}
        faq={
          "Yes. The UKlickMe app helps you find and use promo codes when you're shopping on your phone. It also sends you notifications about great sales and offers, so that you never miss out on a chance to save. You can also find and activate cash back offers via the app and redeem your cash back balance."
        }
      />
      <div className="last-paragraph my-5 pt-3 w-70p resp-w-100p">
      UKlickMe helps you save money while shopping online and in-store at your favorite retailers. Whether you're looking for a promo code, a coupon, a free shipping offer or the latest sales, we're constantly verifying and updating our best offers and deals. Plus, we provide you with cash back offers to get a percentage of what you spend back in your pocket. For an even easier way to save, our browser extension, Deal Finder, finds and automatically applies promo codes to your online shopping carts. Don't forget to check out our blog for our editors' pro shopping tips, the hottest sales, and the best products to buy.
      </div>
    </div>
  );
}

export default FAQ;
