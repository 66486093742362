import React, { useEffect, useState } from "react";
import "../css/Contact.css";

function ContactForm() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 450); // Adjust this width as per your mobile breakpoint
    };

    handleResize(); // Set initial value
    window.addEventListener("resize", handleResize); // Listen to window resize

    return () => window.removeEventListener("resize", handleResize); // Cleanup
  }, []);

  return (
    <div className="contact-container">
      <section>
        <div className="section-header">
          <div className={isMobile ? "" : "container"}>
            <h2>Contact Us</h2>
            <p>
            </p>
          </div>
        </div>

        <div className={isMobile ? "" : "container"}>
          <div className={isMobile ? "" : "row"}>
          <div className="contact-info">
              <div className="contact-info-item">
                <div className="contact-info-icon">
                  <img src="https://cdn-icons-png.flaticon.com/512/25/25694.png"/>
                </div>

                <div className="contact-info-content">
                  <h4>Address</h4>
                  <p>
                  318 Skyline Plaza 3 Shushant
                    <br />Golf City Ansal API Lucknow,<br />
                    226030
                  </p>
                </div>
              </div>

              <div className="contact-info-item">
                <div className="contact-info-icon">
                  <img src="https://cdn2.iconfinder.com/data/icons/font-awesome/1792/phone-512.png"/>
                </div>

                <div className="contact-info-content">
                  <h4>Phone</h4>
                  <p>+91-9044843903</p>
                </div>
              </div>

              <div className="contact-info-item">
                <div className="contact-info-icon email-icon">
                  <img src="https://cdn-icons-png.flaticon.com/512/666/666162.png" />
                </div>

                <div className="contact-info-content">
                  <h4>Email</h4>
                  <p>mohanbhatt007@smarttekmedia.com</p>
                </div>
              </div>
            </div>
            <div className="contact-form">
              <form action="" id="contact-form">
                <h2>Send Message</h2>
                <div className="input-box">
                  <input type="text" required={true} name="" />
                  <span>Full Name</span>
                </div>

                <div className="input-box">
                  <input type="email" required={true} name="" />
                  <span>Email</span>
                </div>

                <div className="input-box">
                  <textarea required={true} name=""></textarea>
                  <span>Type your Message...</span>
                </div>

                <div className="input-box">
                  <input type="submit" value="Send" name="" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ContactForm;
