import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { base } from "../functions/functions.ts";

const MainImage = ({ src }) => (
  <div className="main-image">
    <img src={src} alt="Main" />
  </div>
);

const Carousel = ({ images }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Filter images based on isPhone property and window width
  const filteredImages = images.filter(
    (image) => {
      if (windowWidth <= 430) {
        return image.isPhone;
      } else {
        return !image.isPhone;
      }
    }
  );

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    variableWidth: true,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: false,
    arrow: true,
    dots: true,
  };

  return (
    <div className="carousel-container no-overflow">
      <Slider {...settings}>
        {filteredImages.map((image, index) => (
          <a href={image.backLink} target="_blank" key={index}>
            <div className="mx-3">
              <MainImage src={base + image.url} className="w-80p" />
            </div>
          </a>
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;
