import React, { useEffect, useState } from "react";
import CouponCard from "./res/CouponCard";
import CashbackItem from "./res/CashbackItem";
import { getHomePageOffers, base, getHomePageBrands } from "../functions/functions.ts";

function CouponsAndCashbacks() {
  const [offers, setOffers] = useState([]);
  const [brands, setBrands] = useState([]);

  useEffect(() => {
    getHomePageOffers(setOffers);
    getHomePageBrands(setBrands)
    // console.log(offers)
  }, []);

  return (
    <div>
      <div className="text-gray comission-text text-center my-5 py-1">
        When you buy through links on UKlickMe{" "}
        <span className="underline">we may earn a commission.</span>
      </div>
      <div className="my-4">
        <h5 className="fw-bold my-2">
          The Best Coupons, Promo Codes & Cash Back Offers
        </h5>
        <div className="coupons d-flex flex-column w-100 resp-align-center">
          <div className="d-flex coupons-container w-80vw justify-content-between resp-flex-column-min resp-w-95vw">
            {/* Map offers to CouponCard */}
            {offers.map((offer, index) => (
              <a key={offer._id} href={offer.backLink} target="_blank" className="resp-flex">
                <img src={base + offer.image} className="offer-image resp-my-3 resp-mx-auto" alt={`Offer ${index}`} />
              </a>
            ))}
          </div>
        </div>
      </div>
      <div className="cashbacks my-5">
        <div className="headings d-flex justify-content-between">
          <div className="d-flex flex-column">
            <h6 className="no-overflow fw-bold">BRANDS</h6>
            <h5 className="no-overflow fw-bold">Cash backs at stores we love</h5>
          </div>
          {/* <div className="shop-now fw-500 no-overflow">ALL CASH BACKS</div> */}
        </div>
        <div className="d-flex my-3 justify-content-center flex-wrap">
          {/* Map up to 7 brands or fewer */}
          {brands.slice(0, 7).map((brand, index) => (
            <CashbackItem key={brand._id} brand={brand} />
          ))}
          {/* Render dummy cashback items if there are less than 7 brands */}
          {/* {dummyCashbackItems} */}
        </div>
      </div>
    </div>
  );
}

export default CouponsAndCashbacks;
