import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/App.css";
import "./css/responsive.css";
import "./css/utils.css";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import BrandPage from "./components/res/BrandPage"; // Import BrandPage component

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <div className="content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/home/:brandTitle" element={<BrandPage />} /> {/* Add route for brand pages */}
        </Routes>
        <Footer />
        </div>
      </div>
    </Router>
  );
}

export default App;
