import React, { useState } from "react";

function OrderedListing({
  title,
  list_1,
  list_2,
  list_3,
  list_4,
  list_5,
  faq,
}) {
  const [isCategoriesVisible, setCategoriesVisible] = useState(false);

  const toggleCategories = () => {
    setCategoriesVisible(!isCategoriesVisible);
  };

  return (
    <div>
      <div
        className="d-flex justify-content-between align-items-center mt-4 mb-2 py-1 ordered-head"
        onClick={toggleCategories}
      >
        {faq ? (
          <h6 className="fw-bold">{title}</h6>
        ) : (
          <h5 className="fw-bold">{title}</h5>
        )}
        <div className="open-close-btn" style={{ cursor: "pointer" }}>
          {isCategoriesVisible ? "_" : "+"}
        </div>
      </div>
      <div
        className="mb-4 mt-3 w-60p"
        style={{
          display: isCategoriesVisible ? "flex" : "none",
          transition: "display 0.5s ease-in-out",
        }}
      >
        {faq ? <p>{faq}</p> : ""}
      </div>
      <div
        className="categories justify-content-between mb-4 mt-3"
        style={{
          display: isCategoriesVisible ? "flex" : "none",
          transition: "display 0.5s ease-in-out",
        }}
      >
        {list_1 ? (
          <div className="box_1 d-flex flex-column">
            {list_1.map((item, index) => {
              return (
                <div className="category my-1" key={index}>
                  {item}
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}
        {list_2 ? (
          <div className="box_2 d-flex flex-column">
            {list_2.map((item, index) => {
              return (
                <div className="category my-1" key={index}>
                  {item}
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}
        {list_3 ? (
          <div className="box_3 d-flex flex-column">
            {list_3.map((item, index) => {
              return (
                <div className="category my-1" key={index}>
                  {item}
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}
        {list_4 ? (
          <div className="box_4 d-flex flex-column">
            {list_4.map((item, index) => {
              return (
                <div className="category my-1" key={index}>
                  {item}
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}
        {list_5 ? (
          <div className="box_5 d-flex flex-column">
            {list_5.map((item, index) => {
              return (
                <div className="category my-1" key={index}>
                  {item}
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}
      </div>
      <hr className="ending-hr" />
    </div>
  );
}

export default OrderedListing;
