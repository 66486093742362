import React, { useEffect, useState } from "react";
import Coupon from "./res/Coupon";
import PhoneCoupon from "./res/PhoneCoupon";
import { base, getHomePageProducts } from "../functions/functions.ts";

function TopDeals() {
  const [products, setProducts] = useState([]);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    getHomePageProducts(setProducts);

    // Add event listener for screen size change
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 430);
    };

    window.addEventListener("resize", handleResize);

    // Initial check for screen size on component mount
    setIsSmallScreen(window.innerWidth < 430);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Determine the number of products to display
  const productsToDisplay = products.slice(0, 5); // Display up to 5 products

  // Generate dummy products to fill up to 5
  const dummyProducts = Array.from({ length: Math.max(0, 5 - products.length) }).map((_, index) => ({
    img: "https://dummyimage.com/300x130/ccc/fff&text=Dummy+Product",
    text: "Dummy Product",
    company: "Dummy Company",
  }));

  return (
    <div className="my-5 py-3">
      <h5 className="fw-bold">Top Deals</h5>
      <div className="d-flex justify-content-between my-4 resp-flex-wrap resp-justify-evenly">
        {/* Map the actual products */}
        {productsToDisplay.map((product, index) => {
          if (isSmallScreen) {
            return (
              <PhoneCoupon
                key={index}
                img={base + product.image}
                text={product.title}
                company={product.brand}
                overlayText={product.overlayText}
                redirectLink={product.backLink}
                description={product.description}
                coupon={product.couponCode}
              />
            );
          } else {
            return (
              <Coupon
                key={index}
                img={base + product.image}
                text={product.title}
                company={product.brand}
                overlayText={product.overlayText}
                redirectLink={product.backLink}
                description={product.description}
                coupon={product.couponCode}
              />
            );
          }
        })}
        {/* Map the dummy products if needed */}
        {dummyProducts.map((product, index) => (
          <Coupon
            key={`dummy-${index}`}
            img={product.img}
            text={product.text}
            company={product.company}
          />
        ))}
      </div>
    </div>
  );
}

export default TopDeals;
