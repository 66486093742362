import React from "react";
import { Link } from "react-router-dom"; // Import Link component
import cashback from "../../resources/images/cashback.png";
import { base } from "../../functions/functions.ts";

function CashbackItem({ brand }) {
  if (!brand) {
    return null;
  }

  return (
    <Link to={`/home/${brand.title}`} className="mx-3 resp-mx-05">
      {" "}
      {/* Use Link component with the brand title */}
      <div className="d-flex cashBackItem flex-column resp-align-center my-3">
        <div
          className="cashback_img_container"
          style={{ backgroundColor: `${brand.colorCode}` }}
        >
          <img
            src={
              brand.brandLogo
                ? base + brand.brandLogo
                : "https://www.retailmenot.com/imagery/merchants/06wQz8pSrKpBI7f88YOuvoj-color.fit_limit.quality_80.size_86x86.v1694534449.png.webp"
            }
            alt="Cashback"
            style={{ borderRadius: "50%" }}
          />
        </div>
        <div className="cash-back-text-container"><div className="percent-container d-flex justify-content-center margin-top-lil mt-3">
          <div className="img-container-percent d-flex align-items-center">
            <img src={cashback} alt="Cashback" className="h-20px" />
          </div>
          {/* <h6 className="mx-2 fw-bold no-overflow m-0" style={{color: "black"}}>6%</h6> */}
        </div>
        <div
          className="cash-back text-center fw-bold"
          style={{ color: "black", textTransform: "uppercase" }}
        >
          {brand.title}
        </div></div>
      </div>
    </Link>
  );
}

export default CashbackItem;
