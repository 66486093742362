import React, { useEffect, useState } from "react";
import Carousel from "../components/Carousel";
import CouponsAndCashbacks from "../components/CouponsAndCashbacks";
import SavingWithRetailMeNot from "../components/SavingWithRetailMeNot";
import Coupons from "../components/Coupons";
import RealDeal from "../components/RealDeal";
import TopDeals from "../components/TopDeals";
import OrderedListing from "../components/res/OrderedListing";
import FAQ from "../components/FAQ";
import { getCarouselImages } from "../functions/functions.ts";

function Home() {
  const [carouselImages, setCarouselImages] = useState([
    "https://img.freepik.com/free-photo/abstract-surface-textures-white-concrete-stone-wall_74190-8189.jpg?size=626&ext=jpg&ga=GA1.1.1464404623.1710288000&semt=ais"
  ])
  useEffect(()=>{
    getCarouselImages(setCarouselImages)
  }, [])

  return (
    <div>
      <Carousel
        images={carouselImages}
      />
      <div className="w-80p mx-auto">
        <CouponsAndCashbacks />
        <SavingWithRetailMeNot />
        {/* <Coupons />
        <RealDeal /> */}
        <TopDeals />
        <OrderedListing
          title="POPULAR CATEGORIES"
          list_1={[
            "Baby",
            "Clothing",
            "Flowers",
            "Jewelry",
            "Pizza",
            "TV & Home Theater",
          ]}
          list_2={[
            "Beauty",
            "Cosmetics",
            "Food Delivery",
            "Movie Theaters",
            "Restaurants",
            "Video Games",
          ]}
          list_3={["Books", "Electronics", "Furniture", "Pets", "Shoes"]}
          list_4={[
            "Car Rentals",
            "Fast Food",
            "Home Improvement",
            "Photo",
            "Toys",
          ]}
          list_5={[
            "Cell Phones",
            "Flights",
            "Hotels",
            "Photo Prints",
            "Travel",
          ]}
        />
        <OrderedListing
          title="POPULAR STORES"
          list_1={[
            "Kohl's",
            "Amazon",
            "American Eagle",
            "Spehora",
            "Turbo Tax",
            "Vitacost",
            "Pretty Little Thing",
            "Build.com",
            "Aeropostale",
            "Ancestry.com",
          ]}
          list_2={[
            "Macy's",
            "Shein",
            "Vistaprint",
            "Shutterfly",
            "Office Depot",
            "Booking.com",
            "Saks Off Fifth",
            "Orbitz",
            "Samsung",
            "Men's Wearhouse",
          ]}
          list_3={[
            "Target",
            "Bath & Body Works",
            "Happiest Baby",
            "Express",
            "Lowe's",
            "Saks Fifth Avenue",
            "Coach Outlet",
            "NY & Company",
            "Travelocity",
            "Zoro",
          ]}
          list_4={[
            "Bed Bath & Beyond",
            "Ulta",
            "The Home Depot",
            "Priceline",
            "Carter's",
            "Hotels.com",
            "J. Crew",
            "Hoka One One",
            "Dell",
            "Lenovo",
          ]}
          list_5={[
            "Papa John's",
            "Edible Arrangements",
            "ASOS",
            "Staples",
            "Bloomingdale's",
            "Expedia",
            "Journey's",
            "StubHub",
            "Zales",
            "AT&T Wireless",
          ]}
        />
        <FAQ />
      </div>
    </div>
  );
}

export default Home;
