import axios from "axios";
import React from "react";

// export const base = `http://localhost:4000/`;

// const axiosInstance = axios.create({
//   baseURL: base,
//   headers: {
//     "Access-Control-Allow-Origin": `http://localhost:3001`,
//     Authorization: `Bearer debugflow@#$$`,
//   },
// });

export const base = `https://api.uklickme.com/`;

const axiosInstance = axios.create({
  baseURL: base,
  headers: {
    "Access-Control-Allow-Origin": `https://uklickme.com/`,
    Authorization: `Bearer debugflow@#$$`,
  },
});

export default axiosInstance;

interface Product {
  _id: string;
  title: string;
  image: string;
  description: string;
  category: string;
  couponCode: string;
  overlayText: string;
  brand: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  backLink: string;
}

// export async function createUser(
//     setUsers: React.Dispatch<React.SetStateAction<any[]>>,
//     username: string,
//     email: string,
//     password: string,
//     role: string
//   ) {
//     try {
//       await axiosInstance.post(`/api/user/create`, {
//         user: username,
//         email,
//         password,
//       });
//       getUserData(setUsers, "");
//       successPopup("User created successfully");
//     } catch (error) {
//       errorPopup("Error creating user");
//     }
//   }

export async function getCarouselImages(
  setCarouselImages: React.Dispatch<React.SetStateAction<string[]>>
) {
  try {
    const response = await axiosInstance.get<any>("/api/home/getAllArrays");
    const imagesIdArray: string[] = response.data.homeConfig.carouselImages;
    // const base: string = 'http://example.com/'; // Replace 'http://example.com/' with your base URL
    const links: string[] = [];

    // Using Promise.all to make parallel requests
    await Promise.all(
      imagesIdArray.map(async (element) => {
        try {
          const response = await axiosInstance.post<any>(
            `/api/home/${element}`
          );
          const image: string = response.data.carouselImage;
          links.push(image);
        } catch (error) {
          console.error(`Error fetching image with ID ${element}:`, error);
        }
      })
    );

    if (links.length !== 0) {
      setCarouselImages(links);
    }
  } catch (error) {
    console.error("Error fetching carousel images:", error);
  }
}

export async function getHomePageOffers(
  setOffers: React.Dispatch<React.SetStateAction<string[]>>
) {
  try {
    const response = await axiosInstance.get<any>("/api/home/getAllArrays");
    const offersArray: string[] = response.data.homeConfig.displayOffers;
    const offers: string[] = [];

    // console.log(response)
    // Using Promise.all to make parallel requests
    await Promise.all(
      offersArray.map(async (element) => {
        try {
          const response = await axiosInstance.post<any>(
            `/api/offer/${element}`
          );
          const offer: string = response.data.offer;
          offers.push(offer);
        } catch (error) {
          console.error(`Error fetching offer with ID ${element}:`, error);
        }
      })
    );

    if (offers.length !== 0) {
      setOffers(offers);
    }
  } catch (error) {
    console.error("Error fetching offers:", error);
  }
}

export async function getHomePageBrands(
  setBrands: React.Dispatch<React.SetStateAction<string[]>>
) {
  try {
    const response = await axiosInstance.get<any>("/api/home/getAllArrays");
    const brandsArray: string[] = response.data.homeConfig.displayBrands;
    const brands: string[] = [];

    // console.log(response)
    // Using Promise.all to make parallel requests
    await Promise.all(
      brandsArray.map(async (element) => {
        try {
          const response = await axiosInstance.post<any>(
            `/api/brand/${element}`
          );
          const brand: string = response.data.brand;
          brands.push(brand);
        } catch (error) {
          console.error(`Error fetching brand with ID ${element}:`, error);
        }
      })
    );

    if (brands.length !== 0) {
      setBrands(brands);
    }
  } catch (error) {
    console.error("Error fetching brands:", error);
  }
}

export async function getHomePageProducts(
  setProducts: React.Dispatch<React.SetStateAction<string[]>>
) {
  try {
    const response = await axiosInstance.get<any>("/api/home/getAllArrays");
    const productsArray: string[] = response.data.homeConfig.displayProducts;
    const products: string[] = [];

    // console.log(response)
    // Using Promise.all to make parallel requests
    await Promise.all(
      productsArray.map(async (element) => {
        try {
          const response = await axiosInstance.post<any>(
            `/api/product/${element}`
          );
          // console.log(response)
          const product: string = response.data.product;
          products.push(product);
        } catch (error) {
          console.error(`Error fetching product with ID ${element}:`, error);
        }
      })
    );

    if (products.length !== 0) {
      setProducts(products);
    }
  } catch (error) {
    console.error("Error fetching products:", error);
  }
}

export async function fetchBrandData(
  brandTitle: string,
  setBrandData: React.Dispatch<React.SetStateAction<any[]>>,
  setProducts: React.Dispatch<React.SetStateAction<any[]>>,
  setLoading: React.Dispatch<React.SetStateAction<any>>
) {
  try {
    const response = await axiosInstance.post(
      `api/brand/getByTitle/${brandTitle}`
    );
    const data = response.data.brand;
    setBrandData(data[0]);
    // Check if products exist
    const products: Product[] = [];
    if (data[0]?.products && data[0].products.length > 0) {
        const productPromises: Promise<Product | null>[] = data[0].products.map(
          async (product: any) => {
            console.log(product)
            try {
                const response = await axiosInstance.post(
                    `api/product/${product.value}` // Adjusted to use product.id
                );
                return response.data.product as Product;
            } catch (error) {
                console.error("Error fetching product:", error);
                return null;
            }
        }
        );
    
        Promise.all(productPromises)
            .then((productsData: (Product | null)[]) => {
                // Filter out any null values (failed requests)
                const validProducts = productsData.filter(
                    (product): product is Product => !!product
                );
                // Add valid products to your products array
                products.push(...validProducts);
                // Set the products state after the array is updated
                setProducts(products);
            })
            .catch((error) => {
                console.error("Error fetching products:", error);
            });
    }
    
    setLoading(false); // Set loading to false once data is fetched
  } catch (error) {
    console.error("Error fetching brand data:", error);
    setLoading(false); // Set loading to false in case of error
  }
}
